import React, { useEffect, useState } from "react"
import { scroller } from "react-scroll";
import { Container, Navbar, Nav, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import axios from "axios"
import { isMobile } from "../../utils/device"
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import NoobsLogo from "../../images/NoobsLogoRect.png"
import OpenseaLogo from "../../images/OpenseaLogo.png"
import NGLogo from "../../images/NGLogo.png"
const styles = require("./navBar.module.scss")

type NavBarProps = {
  pageInfo: {
    curPage: string,
  },
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

const CustomNavbar: React.FC<NavBarProps> = ({ pageInfo = { curPage: "/" }, accountAddress, contract, getAndSetAccount }) => {
  const [ownerNoobsMetadata, setOwnerNoobsMetadata] = useState<Object[]>([])

  useEffect(() => {
    if (accountAddress && contract) {
      contract.methods.listNoobsForOwner(accountAddress).call().then(async (ownerNoobs: number[]) => {
        const noobsMetadata = await Promise.all(ownerNoobs.map(async (tokenId) => {
          const tokenUri = await contract.methods.tokenURI(tokenId).call();
          if (tokenUri) {
            const { data: metaData } = await axios.get(tokenUri)
            return { ...metaData, tokenId }
          }
          return { tokenId }
        }))

        setOwnerNoobsMetadata(noobsMetadata);
      }).catch(e => {
        // TODO (Ben): Improve error handling
        console.log(e);
      })
    }
  }, [accountAddress, contract]);

  return (
    <>
      <Navbar collapseOnSelect variant="light" expand="lg" fixed="top" className={styles.navbar}>
        <Container>
          <Navbar.Brand>
            <Nav.Link
              className="nav-brand-image-link"
              href="/#"
              onClick={() => scroller.scrollTo("intro", {
                smooth: true,
                offset: -80,
                duration: 100,
              })}
            >
              <Image 
                src={NoobsLogo} 
                style={{
                  maxHeight: "60px"
                }}
                className="nav-image"
              />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto flex-wrap">
              {pageInfo.curPage === "/noobs" && (
                <div className={styles.noobsNavBar}>
                  <Nav.Link
                    href="/noobs/#about"
                    onClick={() => scroller.scrollTo("about", {
                      smooth: true,
                      offset: -80,
                      duration: 100,
                    })}
                  >
                    ABOUT
                  </Nav.Link>
                  <Nav.Link
                    href="/noobs/#team"
                    onClick={() => scroller.scrollTo("team", {
                      smooth: true,
                      offset: -80,
                      duration: 100,
                    })}
                  >
                    TEAM
                  </Nav.Link>
                  <Nav.Link
                    href="/noobs/#faq"
                    onClick={() => scroller.scrollTo("faq", {
                      smooth: true,
                      offset: -80,
                      duration: 100,
                    })}
                  >
                    FAQ
                  </Nav.Link>
                  {ownerNoobsMetadata.length !== 0 && (
                    <Nav.Link  href="/mynoobs">MY NOOBS</Nav.Link>
                  )}
                </div>
              )}
              <Nav.Link href="/noobs">NOOBS</Nav.Link>
              <Nav.Link href="/punks">PUNKS</Nav.Link>
              <Nav.Link href="/zoo">ZOO</Nav.Link>
            </Nav>
            <Nav>
              <a href="https://twitter.com/noobsnft" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
                <FaTwitter className="nav-image" color={"white"} size={25}/>
              </a>
              <a href="https://discord.gg/6rwBtQWeKY" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
                <FaDiscord className="nav-image" color={"white"} size={25} />
              </a>
              {pageInfo.curPage === "/punks" && (
                <>
                  <a href="https://opensea.io/collection/noobpunks-nft" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
                    <Image
                      src={OpenseaLogo}
                      style={{
                        maxHeight: "25px"
                      }}
                      className="nav-image"
                    />
                  </a>
                </>
              )}
              {pageInfo.curPage === "/zoo" && (
                <>
                  <a href="https://opensea.io/collection/noobzoo" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
                    <Image
                      src={OpenseaLogo}
                      style={{
                        maxHeight: "25px"
                      }}
                      className="nav-image"
                    />
                  </a>
                </>
              )}
              {pageInfo.curPage === "/noobs" && (
                <>
                  <a href="https://opensea.io/collection/noobsnft" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
                    <Image
                      src={OpenseaLogo}
                      style={{
                        maxHeight: "25px"
                      }}
                      className="nav-image"
                    />
                  </a>
                  <a href="https://niftygateway.com/marketplace?artistId=768018" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
                    <Image
                      src={NGLogo}
                      style={{
                        maxHeight: "25px"
                      }}
                      className="nav-image"
                    />
                  </a>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
